<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <div class="bordered pad">
          <h1>
            View certificate
            <a
              target="_blank"
              class="button float-right"
              v-bind:href="certificate.file_location">
                Download certificate
            </a>
          </h1>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
          <h3>Comments</h3>
          <ul class="comments">
            <li v-for="(comment, index) in certificate.comments" v-bind:key="index">
              {{ comment.user.first_name }} {{ comment.user.last_name }} commented on
              {{ comment.created | moment("dddd, MMMM Do YYYY") }}
              <div v-html="comment.comment"></div>
              <div v-if="comment.file_location">
                <a
                  target="_blank"
                  class="button"
                  v-bind:href="comment.file_location">
                  Download file
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
          <h3>Add a new comment</h3>
          <form class="file-form">
            <vue-editor
              id="notesEditor"
              v-bind:editorToolbar="customToolbar"
              v-model="comment.comment"
              placeholder="Notes text"
              rows="12">
            </vue-editor>
            <br />
            <label>File</label>
            <label>Click the button below to select a file to upload</label>
            <div class="file"></div>
            <div><p>{{ filename }}</p></div>
            <button class="button">Save comment</button>
            <div class="UppyProgressBar"></div>
          </form>
        </div>
      </div>
    </div>
    <div
      v-if="message.length > 0"
      class="message-wrap">
      <span class="close-message" v-on:click="message = ''">X</span>
      <p class="message-text">{{ message }}</p>
    </div>
  </div>
</template>

<script>
import axios from '../axios';
import '@uppy/core/dist/style.css';
import '@uppy/file-input/dist/style.css';
import '@uppy/progress-bar/dist/style.css';

const Uppy = require('@uppy/core');
const UppyForm = require('@uppy/form');
const Transloadit = require('@uppy/transloadit');
const ProgressBar = require('@uppy/progress-bar');
const FileInput = require('@uppy/file-input');

export default {
  name: 'ViewFile',
  data() {
    return {
      certificate: {},
      comment: {},
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ header: '1' }, { header: '2' }],
        ['link'],
      ],
      filename: '',
      message: '',
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    userLevel() {
      return this.$store.getters.userLevel;
    },
  },
  methods: {
    saveComment(result) {
      this.comment.file = result;
      this.comment.parentId = this.$route.params.id;
      this.comment.model = 'Certificates';
      axios.post(`/comments/create.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`, this.comment)
        .then((response) => {
          this.message = 'Your comment has been saved.';
          this.certificate.comments.push(response.data.comment);
        });
    },
    getCertificate() {
      const certificateId = this.$route.params.id;
      axios.get(`/certificates/getCertificate/${certificateId}.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`)
        .then((response) => {
          this.certificate = response.data.certificate;
        });
    },
  },
  mounted() {
    this.getCertificate();
    Uppy({
      debug: true,
      autoProceed: false,
      restrictions: {
        maxFileSize: (1000000 * 1000),
        maxNumberOfFiles: 3,
        minNumberOfFiles: 0,
      },
    })
      .use(UppyForm, {
        target: '.file-form',
        multipleResults: true,
        triggerUploadOnSubmit: true,
        submitOnSuccess: false,
      })
      .use(FileInput, {
        id: 'FileInput',
        target: '.file',
        pretty: true,
        inputName: 'comment',
      })
      .on('file-added', (res) => {
        this.filename = res.name;
      })
      .use(ProgressBar, {
        target: '.UppyProgressBar',
        hideAfterFinish: false,
      })
      .use(Transloadit, {
        service: 'https://api2.transloadit.com',
        params: {
          auth: { key: 'db6a1d40944f11e5a0a165fb32f6e49b' },
          template_id: 'ad27d2c2cad8454fba2791c04c58c00e',
        },
        waitForEncoding: true,
        waitForMetadata: false,
        importFromUploadURLs: false,
        alwaysRunAssembly: false,
        signature: null,
      })
      .on('complete', (result) => {
        this.saveComment(result);
      });
  },
};
</script>
